<template>
    <nav>
    <router-link to="/">Cards</router-link> |
    <router-link to="/quizz">Quizz</router-link>
  </nav>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>